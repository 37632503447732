
import { theme } from 'ds4-beta';
import React from 'react';
import styled from 'styled-components';

export const StyledAnalyticsTable = styled.div`
  width: 100%;
`;

export const StyledSummary = styled.p`
  ${theme.typography.body1.regular};
  :first-child {
    margin-top: 0;
  }
`;
export const StyledTableCell = styled.span`
  ${theme.typography.body1.regular};
  word-break: break-word;
`;

export const TableCell = ({ children }: { children: string | Array<string> }) => {
  try {
    if (Array.isArray(children)) {
      return <StyledTableCell>{children.join(" ")}</StyledTableCell>
    }
    if (typeof children !== 'string') {
      throw new Error('Invalid children type'); // Force an error for non-string, non-array input
    }
  } catch (e) {
    console.error(`Error rendering table cell: ${e}. Gracefully exiting.`);
  }
  return <StyledTableCell>{children}</StyledTableCell>;
};

const BAR_CHART_HEIGHT = '300px';
export const StyledBarChartContainer = styled.div`
  max-height: ${BAR_CHART_HEIGHT};
  height: ${BAR_CHART_HEIGHT};
`;