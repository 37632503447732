import { SUPPORTED_EVENTS } from '../constants';

export const getHeader = (event: string) => {
  const { PIM_ANALYTICS, OMS_ANALYTICS } = SUPPORTED_EVENTS;
  switch (event) {
    case OMS_ANALYTICS:
      return 'Orders';
    case PIM_ANALYTICS:
      return 'Products';
    default:
      return '';
  }
};

export const DOMAIN_APP_DEFAULT_ROUTEMAP = {
  product: '/products/items',
  orders: '/orders',
  inventory: '/inventory/manage-inventory',
};

export const MAX_ROWS_LIMIT = 50;
export const MAX_ROWS_LIMIT_EXCEEDS_TEXT =
  `The inquiry yielded over 50 rows of data, with the first ${MAX_ROWS_LIMIT} displayed here; please specify any filtering or viewing adjustments needed for easier analysis.`;
export const COLUMNS_TO_SHOW_CARD = 2;
export const ROWS_TO_SHOW_CARD = 5;

export const MAX_TREND_LINE_LABEL_SIZE = 20;
export const MAX_AXIS_LABEL_SIZE = 15;
export const TREND_TYPES = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
};