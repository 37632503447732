import { DataGrid, theme } from 'ds4-beta';
import React from 'react';
import styled from 'styled-components';
import { getHeader } from './util';
import { getStartCasedString } from '../../../../util';

const StyledTableCell = styled.span`
  ${theme.typography.body1.regular};
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const TableCell = ({ children }: { children: string }) => {
  return <StyledTableCell>{children}</StyledTableCell>;
};

const StyledSummary = styled.p`
  ${theme.typography.body1.regular};
  :first-child {
    margin-top: 0;
  }
`;

const ROW_HEIGHT = 93;
const MAX_ROWS = 5;

const MAX_ROWS_LIMIT = 50;
const MAX_ROWS_LIMIT_EXCEEDS_TEXT =
  'The inquiry yielded over 50 rows of data, with the first 50 displayed here; please specify any filtering or viewing adjustments needed for easier analysis.';

export const AnalyticsTable = ({ data, event }) => {
  const dataset = data?.response?.[0]?.dataset;
  const analysis = data?.response?.[0]?.analysis;
  const cols = dataset?.columns;
  const rows: Array<Record<string, string>> = dataset?.rows ?? [];
  if (!cols) {
    return null;
  }
  return (
    <div>
      <StyledSummary>{analysis?.summary}</StyledSummary>
      <DataGrid
        columns={cols.map(col => ({
          id: col,
          label: getStartCasedString(col),
          width: 100,
          minWidth: 100,
          render: (data: string) => <TableCell>{data}</TableCell>,
        }))}
        height={`${ROW_HEIGHT * Math.min(rows?.length, MAX_ROWS)}px`}
        data={rows.slice(0, MAX_ROWS_LIMIT)}
        isLoading={false}
        headerProps={{
          heading: getHeader(event),
          subTitle:
            rows.length < MAX_ROWS_LIMIT
              ? `Showing ${rows.length} rows`
              : MAX_ROWS_LIMIT_EXCEEDS_TEXT,
        }}
      />
    </div>
  );
};
