import { v4 as uuidv4 } from 'uuid';

export const CHAT_MESSAGE_TYPE = {
  USER: 'user',
  AI: 'ai',
  ERROR: 'error',
} as const;

export const SUPPORTED_EVENT_TYPES = {
  TEXT: 'text',
  JSON: 'json',
} as const;

export const SUPPORTED_EVENTS = {
  PRODUCT_DESCRIPTION: 'product-description',
  OMS_ANALYTICS: 'oms-analytics',
  PIM_ANALYTICS: 'pim-analytics',
  EXCEPTION: 'exception',
} as const;

export const CHAT_STREAMING_PAYLOAD = {
  conversationId: uuidv4(),
  conversationGroup: 'Home',
};

export const CHAT_ERROR_MESSAGE =
  'It looks like there is a connection problem. Please check your internet connection and try again.';

export const HOME_ACTIVITY_ROUTE = '/home/activity';
export const WELCOME_ROUTE = '/welcome';