import startCase from 'lodash/startCase';

const KNOWN_ACRONYMS = ['SEO', 'SKU', 'ID', 'URL'];

export const getStartCasedString = (str: string) => {
  if (!str || str.length === 0) {
    return '';
  }
  return startCase(str)
    .split(' ')
    .map(word => KNOWN_ACRONYMS.includes(word.toUpperCase()) ? word.toUpperCase() : word)
    .join(' ');
};

export const gracefulJSONParse = (jsonString: string) => {
  const cleanedJsonString = jsonString.replace(/\bNaN\b/g, 'null');
  return JSON.parse(cleanedJsonString);
};