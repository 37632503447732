export const TrendTypes = {
  UP: 'up',
  DOWN: 'down',
} as const;

export const TrendStatusTypes = {
  GOOD: 'good',
  BAD: 'bad',
} as const;

export type trendType = typeof TrendTypes[keyof typeof TrendTypes];
export type trendTypeStatus =
  typeof TrendStatusTypes[keyof typeof TrendStatusTypes];

interface Labels {
  [key: string]: string;
}

interface DataRow {
  [key: string]: string | number;
}

interface Visualization {
  chart: boolean;
  chartType?: string;
  xAxis?: string;
  yAxis?: string;
  trendLines?: {
    range: { start: string; end: string };
    value: number;
    label: string;
  }[];
  overview?: {
    title: string;
    range: string;
    descriptor: string;
    currentValues: Record<string, number>;
    valueChange: {
      changeType: string;
      changePercentage: number;
    };
  };
}

interface Dataset {
  labels: Labels;
  columns: string[];
  rows: DataRow[];
  visualization: Visualization;
}

interface ResponseItem {
  dataset: Dataset;
}

export interface Data {
  response: ResponseItem[];
}

export type LineColor = "default" | "red" | "green";

export interface ChartData {
  data: { [x: string]: string | number; name: string | number; }[]
  dataKeys: { name: string; key: string }[];
  trendData?: {
    segment: Array<{ x: number | string; y: number | string }>;
    label: string;
    lineColor: LineColor
    maxLabelSize: number;
  }[];
  title?: string;
  subTitle?: string;
  trendSummary?: {
    descriptor: string;
    metricValue: number;
    trend: trendType;
    prefixSymbol: string;
    value: number;
    trendStatus: trendTypeStatus;
  };
}