import { GridCol, GridRow, theme } from 'ds4-beta';
import styled from 'styled-components';

export const ChatPageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 50px);
`;
export const StyledLandingPageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  margin: 0px auto;
  max-width: 808px;
`;
export const StyledIconLandingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const StyledHeaderContainer = styled.div``;
export const StyledIconContainerRow = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: ${theme.space[8]};
  width: ${theme.space[8]};
`;
export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.space[4]};
  ${theme.typography.h3};
  color: ${theme.color.grey[1000]};
`;
export const StyledSubTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.space[1]};
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[700]};
`;
export const InitialPromptsContainer = styled(GridRow)`
  margin-top: ${theme.space[8]};
`;

export const PredefinedChipContainer = styled(GridCol)``;
