import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { BarChart, theme } from 'ds4-beta';

const MAX_LABEL_SIZE = 20;
const BAR_CHART_HEIGHT = '300px';

const StyledBarChartContainer = styled.div`
  width: calc(100% - ${theme.space[6]});
  max-height: ${BAR_CHART_HEIGHT};
  margin: 0 ${theme.space[4]} ${theme.space[4]} 0;
`;

const transformBarChartData = data => {
  const { visualization, labels, rows } = data?.response?.[0]?.dataset || {};
  const { chart, chartType, xAxis, yAxis, trendLines } = visualization || {};

  if (
    !chart ||
    chartType !== 'bar' ||
    isEmpty(labels) ||
    xAxis == null ||
    yAxis == null
  ) {
    return null;
  }

  const label = labels[yAxis];
  const barchartData = rows.map(row => ({
    name: row[xAxis],
    [yAxis]: row[yAxis],
  }));
  const dataKeys = [{ name: label, key: yAxis }];
  const trendData = trendLines.map(trendline => ({
    segment: [
      {
        x: trendline?.range?.start,
        y: trendline.value,
      },
      {
        x: trendline?.range?.end,
        y: trendline?.value,
      },
    ],
    label: trendline?.label,
    maxLabelSize: MAX_LABEL_SIZE,
  }));

  return {
    data: barchartData,
    dataKeys,
    ...(!isEmpty(trendLines) && { trendData }),
  };
};

export const BarChartView = ({ data }) => {
  const chartData = transformBarChartData(data);

  if (!chartData) return null;

  return (
    <StyledBarChartContainer data-testid='bar-chart'>
      <BarChart
        xInterval='equidistantPreserveStart'
        yInterval='equidistantPreserveStart'
        {...chartData}
      />
    </StyledBarChartContainer>
  );
};
